import ApiService from "@/common/api.service";

export default {
  sendRestLink(data) {
    return ApiService.post("/send-reset-password-email", data);
  },
  sendVerificationLink(data) {
    return ApiService.post("/send-verification-email", data);
  },
  reset(data) {
    return ApiService.post("/reset-password", data);
  },
  getPasswordUrl(data) {
    return ApiService.post("set-url", data);
  }
};
