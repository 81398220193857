import Vue from "vue";
import VueRouter from "vue-router";

import JwtService from "@/common/jwt.service";
import ApiService from "@/common/api.service";
import User from "@/apis/user.js";
import store from "@/store";
import roleMixin from "@/mixins/role.js";
import { MessageBox } from "element-ui";
import i18n from "@/i18n";
import Theme from "@/common/theme";
import baseDomainPath from "@/common/baseDomainPath";
import { replace } from "lodash";

Vue.use(VueRouter);

const routes = [
  // =====  登录  =====
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/Login.vue")
  },
  {
    path: "/password/forgot",
    name: "ForgetPassword",
    component: () => import("@/views/login/ForgetPassword.vue")
  },
  {
    path: "/password/set",
    name: "SetPassword",
    component: () => import("@/views/login/SetPassword.vue")
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/login/ResetPassword.vue")
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/login/Login.vue")
  },
  // =====  登录  =====

  // =====  首页  =====
  {
    path: "/",
    name: "LandingPage",
    component: () => import("@/views/landingPage/Index.vue")
    // redirect: { name: "LandingPageStudent" }
  },
  {
    path: "/students",
    name: "LandingPageStudents",
    component: () => import("@/views/landingPage/Students.vue")
  },
  {
    path: "/educators",
    name: "LandingPageEducators",
    component: () => import("@/views/landingPage/Educators.vue")
  },
  // =====  首页  =====

  // ===== home  =====
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/home/Index.vue"),
    meta: {
      showFooter: false,
      requiresAuth: true
    }
  },
  // ===== home  =====

  // ===== ACT系统 ======
  {
    path: "/act",
    name: "ACTHome",
    component: () => import("@/views/act/Index.vue"),
    redirect: { name: "ACTList" },
    children: [
      {
        path: "list",
        name: "ACTList",
        component: () => import("@/views/act/List.vue"),
        meta: {
          showFooter: false,
          requiresAuth: true
        }
      },
      {
        path: "history",
        name: "ACTHistory",
        component: () => import("@/views/act/History.vue"),
        meta: {
          showFooter: false,
          requiresAuth: true
        },
        children: []
      }
    ]
  },
  {
    path: "/act/result/:userExamId",
    name: "ACTResultPage",
    component: () => import("@/views/act/actResult/ActResult.vue"),
    meta: {
      showFooter: false,
      requiresAuth: true
    }
  },
  {
    path: "/act/test/:examId/:userExamId",
    name: "ACTTest",
    component: () => import("@/views/act/actTest/Index.vue"),
    meta: {
      showHeader: false,
      showFooter: false,
      requiresAuth: true
    }
  },
  {
    path: "/act/explanations/:examId/:userExamId",
    name: "ACTExplanations",
    component: () => import("@/views/act/actResult/Explanation.vue"),
    meta: {
      requiresAuth: true
    }
  }
  // ===== ACT系统 ======
];

const theme = Theme.name;
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // 導向新的 router 會到新頁面的 top
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  const ignoreRemeberPage = [
    "Login",
    "PageNotFound",
    "ForgetPassword",
    "SetPassword",
    "ResetPassword",
    "Verify"
  ];
  // updateFavicon("name", Theme.icon, Theme.image);

  if (!ignoreRemeberPage.includes(to.name)) {
    localStorage.setItem("lastAccessRoutePath", to.fullPath);
  }

  console.log(JwtService.hasToken());
  if (to.meta.requiresAuth && !JwtService.hasToken()) {
    next({ name: "Login" });
  } else {
    // if (JwtService.hasToken()) {
    //   if (!user) {
    //     const getProfile = function() {
    //       return ApiService.query("me");
    //     };
    //     await getProfile()
    //       .then(profile => {
    //         user = profile.user;
    //         // if (user.all_roles.includes("super-admin")) {
    //         //   isAdmin = true;
    //         // }
    //         // if (user.all_roles.includes("teacher")) {
    //         //   isTeacher = true;
    //         // }
    //         // if (user.all_roles.includes("company-admin")) {
    //         //   isCompanyAdmin = true;
    //         // }
    //       })
    //       .catch(res => {
    //         if (theme !== "Ivy-Way") {
    //           next({ name: "Login" });
    //         }
    //       });
    //   }
    //   // if (!isAdmin && to.meta.role === "admin") {
    //   //   next({ name: "PageRole", replace: true });
    //   // }
    //   // if (!isCompanyAdmin && to.meta.role === "company") {
    //   //   next({ name: "PageRole", replace: true });
    //   // }
    //   // if (!isTeacher && to.meta.role === "teacher") {
    //   //   next({ name: "PageRole", replace: true });
    //   // }
    // }
  }
  next();
});
const updateFavicon = (title, imgPath, image) => {
  const link = document.querySelector("link[rel*='icon']");
  link.rel = "icon";
  link.href = baseDomainPath + imgPath + "?" + new Date().getTime();

  const meta = document.querySelector("meta[property*='image']");
  meta.property = "og:image";
  meta.content = image;
};
router.onError(error => {
  const pattern = "Loading chunk";
  const isChunkLoadingFailed = error.message.includes(pattern);
  const cssPattern = "Loading CSS chunk";
  const isCSSChunkLoadingFailed = error.message.includes(cssPattern);
  if (isChunkLoadingFailed || isCSSChunkLoadingFailed) {
    MessageBox({
      title: i18n.t("message.notice"),
      message: `
        ${i18n.t(
    "message.chunkLoadingError1"
  )}<a target="_blank" href="https://www.messenger.com/t/333718851200334/?messaging_source=source%3Apages%3Amessage_shortlink">
        ${i18n.t("message.chunkLoadingError2")}</a>${i18n.t(
  "message.chunkLoadingError3"
)}
      `,
      confirmButtonText: i18n.t("alert.refresh"),
      showClose: false,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      closeOnHashChange: false,
      dangerouslyUseHTMLString: true,
      callback: () => window.location.reload()
    });
  }
});

export default router;
