import baseDomainPath from "@/common/baseDomainPath";
let ThemeDetail = null;

console.log(baseDomainPath);
if (baseDomainPath.indexOf("ivy-way") > -1) {
  // https://sat.ivy-way.com/
  ThemeDetail = {
    themeColor: "#42a16a",
    logo: "https://ivy-way.com/img/logo.png",
    image: "https://testadmit.s3.ap-northeast-1.amazonaws.com/toefl/img/SATLogo.png",
    icon: "IvyWayfavicon.ico",
    name: "Ivy-Way",
    tabName: "Ivy-Way",
    examName: "Ivy-Way",
    linkArr: [
      {
        link: "https://ivy-way.com/about",
        name: "about"
      },
      {
        link: "https://ivy-way.com/instructors",
        name: "instructors"
      },
      {
        link: "https://ivy-way.com/classes",
        name: "classes"
      },
      {
        link: "https://ivy-way.com/stories",
        name: "stories",
        icon: "fas fa-user-graduate"
      },
      {
        link: "https://ivy-way.com/blogs",
        name: "blogs"
      }
    ]
  };
} else if (baseDomainPath.indexOf("primerica") > -1) {
  // https://sat.primerica.com.tw/
  ThemeDetail = {
    themeColor: "#d5a322",
    logo: "https://www.primerica.com.tw/img/logo.fd36bf6b.png",
    image: "https://www.primerica.com.tw/img/logo.fd36bf6b.png",
    icon: "faviconPrimerica.ico",
    name: "Primerica Institute",
    tabName: "Primerica Institute",
    examName: "Primerica Institute",
    //TODO: remove
    // email: "Admin@Test.com",
    email: "",
    isDarkBackground: true,
    backgroundColor: "black",
    isCompany: true,
    linkArr: [
      {
        link: "https://www.primerica.com.tw/ ",
        name: "home"
      }
    ]
  };
} else if (baseDomainPath.indexOf("2400satcenter") > -1) {
  // https://sat.2400satcenter.org/
  ThemeDetail = {
    themeColor: "#163B95",
    logo:
      "https://www.2400satcenter.org/wp-content/uploads/2022/03/cropped-cropped-cropped-logo.jpg",
    image:
      "https://www.2400satcenter.org/wp-content/uploads/2022/03/cropped-cropped-cropped-logo.jpg",
    icon: "sat-center-favicon.ico",
    name: "2400 SAT Learning Center",
    tabName: "2400 SAT Learning Center",
    examName: "2400 SAT",
    email: "",
    isDarkBackground: false,
    isCompany: true,
    linkArr: [
      {
        link: "https://www.2400satcenter.org/ ",
        name: "home"
      }
    ]
  };
} else if (baseDomainPath.indexOf("tyas") > -1) {
  // https://tyas.satmocks.com/
  ThemeDetail = {
    themeColor: "#035ecc",
    logo:
      "https://www.tyas.tyc.edu.tw/__edited_images/CIS%20design/_imagecache/Logo-all-ai.png",
    image:
      "https://www.tyas.tyc.edu.tw/__edited_images/CIS%20design/_imagecache/Logo-all-ai.png",
    icon: "Taoyuan.ico",
    name: "Taoyuan American School",
    tabName: "Taoyuan American School",
    examName: "Taoyuan American School",
    email: "",
    isDarkBackground: true,
    backgroundColor: "#03073b",
    isCompany: true,
    linkArr: [
      {
        link: "https://www.tyas.tyc.edu.tw/",
        name: "home"
      }
    ]
  };
}else {
  ThemeDetail = {
    themeColor: "#063f8b",
    logo: null,
    image: "https://testadmit.s3.ap-northeast-1.amazonaws.com/toefl/img/SATLogo.png",
    icon: "SATfavicon.ico",
    name: "XMocks",
    tabName: "XMocks",
    examName: "XMocks",
    email: "xmocks@gmail.com",
    slug: "x-mocks",
    linkArr: []
  };
}

// ThemeDetail = {
//   themeColor: "#42a16a",
//   logo: "https://ivy-way.com/img/logo.png",
//   image: "https://testadmit.s3.ap-northeast-1.amazonaws.com/toefl/img/SATLogo.png",
//   icon: "IvyWayfavicon.ico",
//   name: "Ivy-Way",
//   tabName: "Ivy-Way",
//   examName: "Ivy-Way",
//   linkArr: [
//     {
//       link: "https://ivy-way.com/about",
//       name: "about"
//     },
//     {
//       link: "https://ivy-way.com/instructors",
//       name: "instructors"
//     },
//     {
//       link: "https://ivy-way.com/classes",
//       name: "classes"
//     },
//     {
//       link: "https://ivy-way.com/stories",
//       name: "stories",
//       icon: "fas fa-user-graduate"
//     },
//     {
//       link: "https://ivy-way.com/blogs",
//       name: "blogs"
//     }
//   ]
// };


export default ThemeDetail;
